<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="(isShow ? 'red' : 'green') + ' darken-1' "
        dark
        text
        outlined
        fab
        v-bind="attrs"
        v-on="on"
        :title="$t( `resources.projects.${ isShow ? 'hide' : 'show' }`)"
      >
        <v-icon
          dark
        >{{ isShow ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 yellow-new lighten-2 title-dialog">
        {{ item.name }}
      </v-card-title>

      <v-card-text>
        {{ $t(`resources.projects.text${ isShow ? 'Hide' : 'Show' }`, { project: item.name }) }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="warning"
          text
          @click="dialog = false"
        >
          {{ $t("va.close") }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          :color="isShow ? 'error' : 'success'"
          text
          @click="update"
        >
          {{ $t(`resources.projects.${ isShow ? 'hide' : 'show' }`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ShowProjectButton",
  props: {
    item: Object,
    icon: Boolean,
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    isShow() {
      return this.item.is_show;
    }
  },
  methods: {
    async update() {
      const action = this.isShow ? "hiding" : "showing";
      try {
        this.$emit('updateStarted');
        const { data: { data } } = await this.$admin.http.post(`/api/projects/${ this.item.id }/${ action }`);
        this.dialog = false;
        this.$emit('updateItem', data);
      } catch ({ response }) {
        this.$snackbar.error(response.data.message);
      } finally {
        this.$emit('updateFinished');
      }
    },
  },
};
</script>
<style scoped>
.title-dialog {
  padding: 16px 24px 10px !important;
  font-weight: bold !important;
}
</style>
