<template>
  <list
    v-if="showAllIssues && project.id"
    ref="list"
    disable-export
    resource="issues"
    :filter="{ project_id: this.project.id }"
    :filters="filters"
    disable-create-redirect
    disable-create
    disable-query-string
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    @updateSortBy="showSortBy"
  >
    <list-view
      :isShowActionButton="isShowActionButton"
      :isUpdatingIssues="isUpdatingIssues"
      :addItemMyIssue="addItemMyIssue"
      :fields="fields"
      :sortBy.sync="sortBy"
      :sortDesc.sync="sortDesc"
      def-sort="id"
    />
  </list>
</template>

<script>
import List from "@/components/List";
import ListView from "@/components/ListView";
import {
  createNamespacedHelpers,
  mapState as rootMapState,
  mapGetters as mapRootGetters,
} from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("dashboard");

export default {
  name: "ListAll",
  props: [],
  components: {
    List,
    ListView,
  },
  data() {
    return {
      sortBy: ["id"],
      sortDesc: [true],
      fields: [
        {
          source: "id",
          sortable: true,
          width: 100,
        },
        {
          source: "tracker.name",
          sortable: true,
          width: 220,
        },
        {
          source: "status.name",
          sortable: true,
          width: 120,
        },
        {
          source: "priority.name",
          sortable: true,
          width: 120,
        },
        {
          source: "subject",
          sortable: true,
        },
        {
          source: "created_on",
          type: "date",
          attributes: {
            format: "dateShort",
          },
          sortable: true,
          width: 140,
        },
        {
          source: "author",
          sortable: true,
          width: 150,
        },
      ],
    };
  },
  computed: {
    ...mapRootGetters({
      permissions: "auth/getPermissions",
    }),
    ...rootMapState({
      project: "currentProject",
    }),
    ...mapState([
      "isUpdatingIssues",
      "showAllIssues",
      "myIssues",
      "maxMyIssues",
      "priorities",
      "trackers",
      "statuses",
    ]),
    isShowActionButton() {
      return (
        this.permissions.includes("dealer") &&
        this.myIssues.length < this.maxMyIssues
      );
    },
    filters() {
      return [
        {
          source: "status_id",
          type: "autocomplete",
          attributes: {
            text: "name",
            chip: true,
            color: "orange",
            itemText: (i) => `${i.name} ${i.is_closed ? " (x)" : ""}`,
            multiple: true,
            itemValue: "id",
            choices: this.statuses,
          },
        },
        {
          source: "tracker_id",
          type: "autocomplete",
          attributes: {
            text: "name",
            chip: true,
            color: "orange",
            itemText: "name",
            multiple: true,
            itemValue: "id",
            choices: this.trackers,
          },
        },
        {
          source: "priority_id",
          type: "autocomplete",
          attributes: {
            text: "name",
            chip: true,
            color: "orange",
            itemText: "name",
            multiple: true,
            itemValue: "id",
            choices: this.priorities,
          },
        },
        "author",
        {
          source: "created_start",
          type: "date",
          attributes: {
            format: "dateShort",
          },
        },
        {
          source: "created_end",
          type: "date",
          attributes: {
            format: "dateShort",
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions(["addItemMyIssue"]),
    showSortBy(values) {
      if (values.length === 0) {
        this.sortBy = ["id"];
      }
    },
  },
};
</script>
