<template>
  <data-table
    :fields="fields"
    show-expand
    disable-create-redirect
    disable-show-redirect
    disable-edit-redirect
    disable-edit
    disable-show
    disable-clone
    disable-delete
    disableSelect
    must-sort
    :multi-sort="false"
    @row-click="rowClick"
  >
    <template v-slot:expanded-item="{ item }">
      <span v-html="item.description"></span>
    </template>
    <template v-slot:item.actions="{ item }" v-if="!disableAction">
      <va-action-button
        v-if="isShowActionButton && !item.rate"
        :loading="isUpdatingIssues"
        :label="$t('resources.issues.add')"
        icon="mdi-publish"
        color="success"
        @click="addItemMyIssue([item])"
        text
      ></va-action-button>
      <div v-else-if="item.rate" class="text-center orange--text">
        {{ $t("resources.issues.messages.inner in issues") }}
      </div>
    </template>
    <template v-slot:[`field.id`]="{ item }">
      <a :href="`${item.url}`" target="_blank">{{ item.id }}</a>
    </template>
  </data-table>
</template>

<script>
import DataTable from "@/components/DataTable";
export default {
  components: { DataTable },
  props: {
    isShowActionButton: {
      type: Boolean,
      default: () => false,
    },
    isUpdatingIssues: {
      type: Boolean,
      default: () => false,
    },
    addItemMyIssue: {
      type: Function,
      default: () => {},
    },
    fields: {
      type: Array,
      default: () => [],
    },
    sortBy: {
      type: Array,
      default: () => [],
    },
    sortDesc: {
      type: Array,
      default: () => [],
    },
    defSort: {
      type: String,
      default: () => "id",
    },
    disableAction: {
      type: Boolean,
      default: () => false,
    },
    showRate: {
      type: Boolean,
      default: () => false,
    },
  },
  inject: {
    listState: { default: undefined },
  },
  computed: {
    getSortBy() {
      return this.sortBy && this.sortBy.length ? this.sortBy[0] : this.defSort;
    },
    getSortDesc() {
      return this.sortDesc && this.sortDesc.length ? this.sortDesc[0] : false;
    },
  },
  methods: {
    rowClick(value) {
      this.$emit("onRowClick", value);
    },
  },
};
</script>
