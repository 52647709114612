<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        :icon="false"
        text
        @click="onBulkUpdate"
        :color="color"
        v-on="on"
        :loading="sync"
      >
        <v-icon small>{{ icon }}</v-icon>
        <span v-if="!hideLabel && $vuetify.breakpoint.lgAndUp" class="ml-2">
          {{ label }}
        </span>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import i18n from "@/i18n";
import ActionButton from "vuetify-admin/src/mixins/action-button";

/**
 * Generic customizable button for update bulk actions in VaList component.
 * Shown after items selections. Use `updateMany` data provider method under the hood.
 */
export default {
  mixins: [ActionButton],
  inject: {
    listState: { default: undefined },
  },
  props: ['tooltip'],
  data() {
    return {
      sync: false
    };
  },
  methods: {
    async onBulkUpdate() {
      this.sync = true;
      this.$emit("startSync");
      try {
        await this.$store.dispatch(`${ this.listState.resource }/update`, {
          id: 'sync',
          data: 'sync',
        });
      } catch (error) {
        console.error(error)
        this.$store.commit(
          'messages/showToast',
          { color: 'red', message: i18n.t("errors." + error.message) },
          { root: true }
        );
      }
      this.$emit("endSync");
      this.sync = false;
    },
  },
};
</script>
