<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-text-field source="id"></va-text-field>
    <va-text-input source="value"></va-text-input>
    <v-card class="mb-3">
      <v-card-title>{{ $t('resources.settings.fields.description') }}</v-card-title>
      <v-card-text>{{ item.description }}</v-card-text>
    </v-card>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item"],
};
</script>
