import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  CHECK_ERROR,
  GET_NAME,
  GET_EMAIL,
  GET_PERMISSIONS,
} from "vuetify-admin/src/providers/auth/actions";

import FetchHydra from "vuetify-admin/src/providers/utils/fetchHydra";

export default (httpClient, params = {}) => {
  if (typeof httpClient === "string") {
    httpClient = new FetchHydra(httpClient);
  }

  params = {
    routes: {
      login: "api/auth/login",
      logout: "api/auth/logout",
      refresh: "api/auth/refresh",
      user: "api/auth/me",
    },
    storageKey: "jwt_token",
    storageExpires: "jwt_expires",
    userKey: "user",
    getToken: (r) => r.access_token,
    setUser: (r) => JSON.stringify(r),
    getUser: (r) => JSON.parse(r),
    getExpiresTime: (r) => r.expires_in,
    getTime: (time) => new Date(time * 1000 + new Date().getTime()).getTime(),
    getCredentials: ({ username, password }) => {
      return {
        email: username,
        password,
      };
    },
    getName: (u) => u.name,
    getEmail: (u) => u.email,
    getPermissions: (u) => u.roles,
    ...params,
  };

  let {
    routes,
    storageKey,
    storageExpires,
    userKey,
    setUser,
    getUser,
    getCredentials,
    getName,
    getEmail,
    getPermissions,
    getToken,
    getExpiresTime,
    getTime,
  } = params;

  const clearStorage = () => {
    localStorage.removeItem(storageKey);
    localStorage.removeItem(storageExpires);
    localStorage.removeItem(userKey);
  };

  return {
    [LOGIN]: async ({ username, password }) => {
      let response = await httpClient.post(
        routes.login,
        getCredentials({ username, password })
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      localStorage.setItem(storageKey, getToken(response.data));
      localStorage.setItem(
        storageExpires,
        getTime(getExpiresTime(response.data))
      );

      return Promise.resolve();
    },
    [LOGOUT]: async () => {
      if (routes.logout) {
        try {
          let key = localStorage.getItem(storageKey);
          if (key) {
            await httpClient.post(routes.logout);
          }
        } catch (error) {
          console.error(error);
        }
      }

      clearStorage();

      return Promise.resolve();
    },
    [CHECK_AUTH]: async () => {
      let key = localStorage.getItem(storageKey);
      let expiresTime = localStorage.getItem(storageExpires);

      expiresTime =
        expiresTime && /^(\d*)$/.test(expiresTime)
          ? parseInt(expiresTime)
          : expiresTime;

      if (
        !key ||
        key.length === 0 ||
        !expiresTime ||
        expiresTime < new Date().getTime()
      ) {
        throw new Error("Not auth key");
      }

      const now = new Date().getTime();
      httpClient.defaults.headers.common = { Authorization: `Bearer ${key}` };

      // 10 minutes before expire
      if (expiresTime - 10 * 60 * 1000 < now) {
        let response = await httpClient.post(routes.refresh);

        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        key = getToken(response.data);
        localStorage.setItem(storageKey, key);
        localStorage.setItem(
          storageExpires,
          getTime(getExpiresTime(response.data))
        );
        httpClient.defaults.headers.common = { Authorization: `Bearer ${key}` };
      }

      const user = getUser(localStorage.getItem(userKey));

      if (user) {
        return Promise.resolve({
          data: user,
        });
      }

      /**
       * Get user infos
       */
      if (routes.user) {
        let response = await httpClient.post(routes.user);

        if (response.status < 200 || response.status >= 300) {
          localStorage.removeItem(userKey);
          throw new Error(response.statusText);
        }
        localStorage.setItem(userKey, setUser(response.data));

        return response;
      }

      return Promise.resolve({ data: true });
    },
    [CHECK_ERROR]: ({ status }) => {
      if (status === 401) {
        clearStorage();

        return Promise.reject();
      }

      if (status === 403) {
        let expiresTime = localStorage.getItem(storageExpires);

        expiresTime =
          expiresTime && /^(\d*)$/.test(expiresTime)
            ? parseInt(expiresTime)
            : expiresTime;

        if (!expiresTime || expiresTime < new Date().getTime()) {
          return Promise.reject();
        }
      }

      return Promise.resolve();
    },
    [GET_NAME]: (user) => getName(user),
    [GET_EMAIL]: (user) => getEmail(user),
    [GET_PERMISSIONS]: (user) => getPermissions(user),
  };
};
