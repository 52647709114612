<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="$emit('saved')"
    v-model="model"
    aria-autocomplete="none"
    autocomplete="off"
  >
    <va-text-input source="name"></va-text-input>
    <va-text-input source="email" type="email"></va-text-input>
    <va-password-input
      source="password"
      aria-autocomplete="none"
      autocomplete="off"
    ></va-password-input>
    <va-password-input
      source="password_confirmation"
      aria-autocomplete="none"
      autocomplete="off"
    ></va-password-input>
    <va-boolean-input source="active"></va-boolean-input>
    <va-select-input
      source="roles"
      multiple
      :reference="'roles'"
    ></va-select-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item"],
  data() {
    return {
      model: {
        active: true,
        roles: ["dealer"],
      },
      roles: [
        {
          value: "admin",
          text: "admin1",
        },
        {
          value: "manager",
          text: "manager1",
        },
        {
          value: "dealer",
          text: "dealer1",
        },
      ],
    };
  },
};
</script>
