<template>
  <div>
    <v-progress-linear
      indeterminate
      absolute
      top
      style="top: 58px"
      color="yellow"
      :active="allLoaded"
    ></v-progress-linear>
    <base-material-card class="px-5 py-3" v-if="isInit">
      <template v-slot:heading>
        <tab-project icon="mdi-view-dashboard" />
      </template>

      <list-all />
      <list-my />
      <list-archive />
    </base-material-card>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapState as rootMapState } from "vuex";
import { ListAll, ListArchive, ListMy, TabProject } from "./dashboard/index.js";

const { mapGetters, mapActions } = createNamespacedHelpers("dashboard");
export default {
  components: {
    ListAll,
    ListArchive,
    ListMy,
    TabProject,
  },
  data() {
    return {
      statuses: [],
      trackers: [],
      priorities: [],
    };
  },
  created() {
    if (this.$admin.can(["dealer", "admin"]) === false) {
      this.$router.push("/results");
    }
    this.getProjects(true);
    this.getFields();
  },
  computed: {
    ...rootMapState(["isInit"]),
    ...mapGetters(["allLoaded"]),
  },
  methods: {
    ...mapActions(["getProjects", "getFields"]),
  },
};
</script>
<style>
.v-toolbar__content button.red--text {
  display: none !important;
}

.my-switch .v-input--selection-controls__input {
  margin-left: 8px;
}

.v-data-table__expanded.v-data-table__expanded__content td {
  padding: 8px 16px !important;
}
</style>
