<template>
  <div>
    <v-progress-linear
      indeterminate
      absolute
      top
      style="top: 58px"
      color="yellow"
      :active="loading"
    ></v-progress-linear>

    <base-material-card :icon="resource.icon" :title="title">
      <v-text-field
        v-model="search"
        :label="$t('va.search')"
        solo
        flat
        hide-details
        dense
        clearable
        clear-icon="mdi-close-circle-outline"
        prepend-icon="mdi-magnify"
        outlined
        class="ma-3"
      >
      </v-text-field>
      <v-treeview
        ref="treeview"
        :disabled="loading"
        :items="items"
        :search="search"
        :filter="filter"
        :selectable="false"
        open-all
        :load-children="null"
      >
        <template v-slot:label="{ item }">
          <slot name="item" :item="item">
            <div :class="{ show: item.is_show, 'grey--text': !item.is_show }">
              <small>{{ item.id }}</small> <b>{{ item.name }}</b> ({{
                item.identifier
              }})
              <small :title="$t('resources.projects.fields.last_update_date')">
                {{ $d(new Date(item.last_update_date), "short") }}
              </small>
              <show-project-button
                :resource="resource"
                :item="item"
                icon
                @updateStarted="updateStarted"
                @updateFinished="updateFinished"
              ></show-project-button>
            </div>
          </slot>
        </template>
      </v-treeview>
    </base-material-card>
  </div>
</template>

<script>
import ShowProjectButton from "@/components/buttons/ShowProjectButton";
import get from "lodash/get";

export default {
  props: ["resource", "title"],
  components: { ShowProjectButton },
  beforeCreate() {
    if (!this.$admin.can(["admin"])) {
      this.$router.push("/");
    }
    return false;
  },
  mounted() {
    this.fetchTree();
  },
  data() {
    return {
      search: null,
      items: [],
      loading: false,
    };
  },
  computed: {
    filter() {
      return (item, search) => {
        search = search.toString().toLowerCase();
        return ["name", "identifier", "id"].some(
          (field) =>
            get(item, field).toString().toLocaleLowerCase().indexOf(search) > -1
        );
      };
    },
  },
  methods: {
    updateStarted() {
      this.loading = true;
    },
    async updateFinished() {
      try {
        await this.fetchTree();
      } finally {
        this.loading = false;
      }
    },
    async fetchTree() {
      this.loading = true;

      /**
       * Load hierarchical data list
       */
      let data = await this.$store.dispatch(`${this.resource.name}/getTree`, {
        filter: this.filter,
      });

      this.loading = false;

      this.items = data;

      this.$nextTick(() => {
        this.$refs.treeview.updateAll(true);
      });
    },
  },
};
</script>
