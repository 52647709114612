import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";
import axios from "axios";
import trimEnd from "lodash/trimEnd";
import "vuetify-admin/src/loader";
import { en } from "vuetify-admin/src/locales";

import laravelDataProvider from "@/providers/data/laravel";
import jwtAuthProvider from "@/providers/auth/jwt";
import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
const baseURL = process.env.VUE_APP_API_URL || "http://localhost:8000";

const http = axios.create({
  baseURL,
  withCredentials: true,
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

export const apiProvider = laravelDataProvider(http);

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "Задачи",
  routes,
  locales: {
    en,
  },
  translations: ["en", "ru"],
  dataProvider: apiProvider,
  authProvider: jwtAuthProvider(http),
  resources,
  http,
  options: {
    dateFormat: "long",
    tinyMCE: {
      language: navigator.language.replace("-", "_"),
      imageUploadUrl: "/api/upload",
      fileBrowserUrl: `${trimEnd(baseURL, "/")}/elfinder/tinymce5`,
    },
  },
});
