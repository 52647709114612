import Vue from "vue";
import { GET_LIST } from "vuetify-admin/src/providers/data/actions";
import Vuex from "vuex";
import dashboard from "@/store/dashboard";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    projects: [],
    currentProject: {},
    isInit: false,
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = projects || [];
    },
    setCurrentProject(state, project) {
      state.currentProject = project || {};
    },
    setIsInit(state) {
      state.isInit = true;
    },
  },
  actions: {
    async getProjects({ commit, dispatch, state }, force = false) {
      if (state.isInit && state.projects.length > 0 && !force) {
        return state.projects;
      }

      let { data: projects } = await dispatch(`projects/${GET_LIST}`, null, {
        root: true,
      });
      if (!projects || projects.length === 0) {
        commit("setProjects", []);
        return;
      }

      commit("setProjects", projects);
      commit("setCurrentProject", projects[0]);
      await dispatch("dashboard/getMyIssues");
      commit("setIsInit");
    },
  },
  modules: {
    dashboard,
  },
});
