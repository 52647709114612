<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <settings-form
        v-if="!show"
        :id="id"
        :item="item"
        @saved="onSaved"
      ></settings-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <v-progress-linear
        v-if="isSync"
        class="ma-2"
        indeterminate
        color="yellow darken-2"
      ></v-progress-linear>
      <va-list
        ref="list"
        disable-create-redirect
        @action="onAction"
        disable-export
        disable-create
      >
        <template v-slot:actions>
          <sync-action-button
            :label="$t('resources.settings.sync')"
            :tooltip="$t('resources.settings.syncMessage')"
            icon="mdi-cached"
            color="orange"
            @startSync="startSync"
            @endSync="endSync"
          ></sync-action-button>
        </template>

        <va-data-table
          :fields="fields"
          disable-show
          disable-clone
          disable-delete
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          disable-select
          @item-action="onAction"
        ></va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import SyncActionButton from "@/components/buttons/SyncActionButton";
export default {
  props: ["resource", "title"],
  components: { SyncActionButton },
  data() {
    return {
      fields: [
        { source: "id", sortable: true },
        { source: "value" },
        { source: "description" },
      ],
      isSync: false,
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    endSync() {
      this.isSync = false;
    },
    startSync() {
      this.isSync = true;
    },
  },
};
</script>
