<template>
  <div v-if="myIsArchive">
    <v-divider class="ma-10" />
    <v-card v-if="!showAllIssues && project.id" class="">
      <v-card-title class="yellow pa-5 elevation-6 mb-3">
        <span class="text-subtitle-1">{{
          $t("resources.issues.messages.archive_title")
        }}</span></v-card-title
      >
      <v-card-text>
        <list
          ref="list"
          disable-export
          resource="archives"
          :filter="{ project_id: this.project.id }"
          :filters="filters"
          disable-create-redirect
          disable-create
          disable-query-string
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @updateSortBy="showSortBy"
        >
          <list-view
            :isShowActionButton="false"
            :isUpdatingIssues="false"
            :fields="fields"
            :sortBy.sync="sortBy"
            :sortDesc.sync="sortDesc"
            def-sort="id"
            :disable-action="true"
          />
        </list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import List from "@/components/List";
import ListView from "@/components/ListView";
import { createNamespacedHelpers, mapState as rootMapState } from "vuex";

const { mapState } = createNamespacedHelpers("dashboard");

export default {
  name: "ListArchive",
  components: { ListView, List },
  data() {
    return {
      sortBy: ["id"],
      sortDesc: [true],
      fields: [
        {
          source: "id",
          sortable: true,
          width: 100,
        },
        {
          source: "tracker.name",
          sortable: true,
          width: 220,
        },
        {
          source: "status.name",
          sortable: true,
          width: 120,
        },
        {
          source: "priority.name",
          sortable: true,
          width: 120,
        },
        {
          source: "subject",
          sortable: true,
        },
        {
          source: "created_on",
          type: "date",
          attributes: {
            format: "dateShort",
          },
          sortable: true,
          width: 140,
        },
        {
          source: "author",
          sortable: true,
          width: 150,
        },
      ],
    };
  },
  computed: {
    ...rootMapState({
      project: "currentProject",
    }),
    ...mapState([
      "showAllIssues",
      "myIsArchive",
      "priorities",
      "trackers",
      "statuses",
    ]),
    filters() {
      return [
        {
          source: "status_id",
          type: "autocomplete",
          attributes: {
            text: "name",
            chip: true,
            color: "orange",
            itemText: (i) => `${i.name} ${i.is_closed ? " (x)" : ""}`,
            multiple: true,
            itemValue: "id",
            choices: this.statuses,
          },
        },
        {
          source: "tracker_id",
          type: "autocomplete",
          attributes: {
            text: "name",
            chip: true,
            color: "orange",
            itemText: "name",
            multiple: true,
            itemValue: "id",
            choices: this.trackers,
          },
        },
        {
          source: "priority_id",
          type: "autocomplete",
          attributes: {
            text: "name",
            chip: true,
            color: "orange",
            itemText: "name",
            multiple: true,
            itemValue: "id",
            choices: this.priorities,
          },
        },
        "author",
        {
          source: "created_start",
          type: "date",
          attributes: {
            format: "dateShort",
          },
        },
        {
          source: "created_end",
          type: "date",
          attributes: {
            format: "dateShort",
          },
        },
      ];
    },
  },
  methods: {
    showSortBy(values) {
      if (values.length === 0) {
        this.sortBy = ["id"];
      }
    },
  },
};
</script>
