<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <users-show v-if="show" :item="item"></users-show>
      <users-form v-else :id="id" :item="item" @saved="onSaved"></users-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        disable-create-redirect
        disable-export
        @action="onAction"
      >
        <template v-slot:[`bulk.actions`]>
          <va-bulk-action-button
            :label="$t('users.enable')"
            icon="mdi-publish"
            color="success"
            :action="{ active: true }"
            text
          ></va-bulk-action-button>
          <va-bulk-action-button
            :label="$t('users.disable')"
            icon="mdi-download"
            color="orange"
            :action="{ active: false }"
            text
          ></va-bulk-action-button>
        </template>
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
          <template v-slot:[`field.roles`]="{ value }">
            <v-chip-group column>
              <v-chip
                color="yellow"
                small
                v-for="item in value"
                :key="item.value"
              >
                <va-select-field
                  source="roles"
                  :item="item.label"
                ></va-select-field>
              </v-chip>
            </v-chip-group>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        { source: "active", type: "boolean" },
        {
          source: "roles",
          type: "select",
          attributes: {
            multiple: true,
            chip: true,
            color: "orange",
            itemText: "label",
            itemValue: "id",
            choices: [
              {
                id: "admin",
                label: "Администратор",
              },
              {
                id: "dealer",
                label: "Дилер",
              },
              {
                id: "viewer",
                label: "Просмотр",
              },
            ],
          },
        },
      ],
      fields: [
        { source: "name", sortable: true },
        { source: "user_issues", type: "text" },
        { source: "email", type: "email" },
        { source: "active", type: "boolean", editable: true },
        { source: "roles" },
        {
          source: "created_at",
          type: "date",
          sortable: true,
          attributes: { format: "short" },
        },
        {
          source: "updated_at",
          type: "date",
          sortable: true,
          attributes: { format: "short" },
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },

};
</script>
