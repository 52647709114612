<template>
  <v-tabs v-model="tab" background-color="transparent" slider-color="gray">
    <v-icon size="32">{{ icon }}</v-icon>
    <span class="subheading font-weight-light mx-3" style="align-self: center">
      {{ $tc("resources.projects.name", tabs.length) }}
    </span>
    <v-tab class="mr-3" v-for="prj in tabs" :key="prj.id">
      {{ prj.name }}
    </v-tab>
    <v-spacer></v-spacer>
    <v-tooltip bottom right v-show="!hideSwitch">
      <template v-slot:activator="{ on }">
        <span v-on="on" v-show="!hideSwitch">
          <label class="left-label v-label theme--light">Приоритетные</label>
          <v-switch
            :input-value="showAllIssues"
            @change="showAllHandler"
            label="Все"
            class="my-switch"
            light
            dense
          ></v-switch>
        </span>
      </template>
      <span v-if="!showAllIssues">
        Показать все задачи, скрыть приоритетные задачи
      </span>
      <span v-if="showAllIssues">
        Показать приоритетные задачи, скрыть все задачи
      </span>
    </v-tooltip>
  </v-tabs>
</template>

<script>
import { createNamespacedHelpers, mapState as mapStateRoot } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("dashboard");

export default {
  name: "TabProject",
  props: {
    hideSwitch: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "mdi-car-shift-pattern",
    },
  },
  computed: {
    ...mapStateRoot({
      tabs: "projects",
    }),
    ...mapState(["showAllIssues"]),
    tab: {
      get() {
        const project = this.$store.state["currentProject"];
        if (!project) {
          return null;
        }

        return this.tabs.findIndex((i) => i.id === project.id);
      },
      set(value) {
        this.$store.dispatch("dashboard/setProject", this.tabs[value]);
      },
    },
  },
  methods: {
    ...mapActions({
      showAllHandler: "showAllIssues",
    }),
  },
};
</script>

<style scoped>
.my-switch {
  display: inline-block;
}
</style>
