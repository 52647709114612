export default [
  { name: "issues", routes: [] },
  {
    name: "projects",
    icon: "mdi-car-shift-pattern",
    routes: ["list"],
    permissions: ["admin"],
  },
  {
    name: "results",
    icon: "mdi-all-inclusive",
    routes: ["list"],
  },
  {
    name: "settings",
    icon: "mdi-cogs",
    routes: ["list", "edit"],
    permissions: ["admin"],
  },
  {
    name: "users",
    icon: "mdi-account-group",
    routes: ["list"],
    permissions: ["admin"],
  },
  { name: "fields", routes: [] },
  { name: "archives", routes: [] },
  { name: "roles", routes: [] },
];
