import { array_move } from "@/helpers/helpers";
import { GET_ONE, UPDATE } from "vuetify-admin/src/providers/data/actions";
import i18n from "@/i18n";
export default {
  namespaced: true,
  state: {
    showAllIssues: false,
    myIsArchive: false,
    myIssues: [],
    myIssuesLoading: false,
    selectedIssues: [],
    maxMyIssues: 10,
    isUpdatingIssues: false,
    priorities: [],
    statuses: [],
    trackers: [],
  },

  getters: {
    allLoaded: (state, getters, rootState) =>
      !rootState.isInit || state.myIssuesLoading || state.isUpdatingIssues,
  },

  mutations: {
    setMyIsArchive(state, value) {
      state.myIsArchive = !!value;
    },
    setMyIssues(state, issues) {
      state.myIssues = issues || [];
    },
    myIssuesLoading(state, value) {
      state.myIssuesLoading = !!value;
    },
    showAllIssues(state, value) {
      state.showAllIssues = !!value;
    },
    maxMyIssues(state, value) {
      state.maxMyIssues = value;
    },
    isUpdatingIssues(state, value) {
      state.isUpdatingIssues = value;
    },
    priorities(state, value) {
      state.priorities = value;
    },
    trackers(state, value) {
      state.trackers = value;
    },
    statuses(state, value) {
      state.statuses = value;
    },
  },

  actions: {
    async getProjects({ commit, dispatch, state }, check = false) {
      await dispatch(`getProjects`, null, {
        root: true,
      });

      if (check && state.myIssues.length === 0) {
        commit("showAllIssues", true);
      }
    },
    async setProject({ commit, dispatch }, project) {
      commit("setCurrentProject", project, { root: true });
      return await dispatch("getMyIssues");
    },
    showAllIssues({ commit }, value) {
      commit("showAllIssues", value);
    },
    async getMyIssues({ commit, dispatch, rootState }) {
      if (!rootState.currentProject || !rootState.currentProject.id) {
        return;
      }
      commit("setMyIsArchive", false);
      commit("setMyIssues", []);
      commit("myIssuesLoading", true);
      let {
        data: issues,
        maxIssue,
        isArchive,
      } = await dispatch(
        `issues/${GET_ONE}`,
        {
          id: "me",
          options: {
            project_id: rootState.currentProject.id,
          },
        },
        { root: true }
      );
      if (!issues || issues.length === 0) {
        issues = [];
      }

      commit("setMyIsArchive", isArchive);
      commit("setMyIssues", issues);
      commit("maxMyIssues", maxIssue);
      commit("myIssuesLoading", false);
    },
    async setProjects({ commit, dispatch }, projects) {
      if (!projects || projects.length === 0) {
        return;
      }

      commit("setProjects", projects);
      dispatch("setProject", projects[0]);
    },
    async updateMyIssues({ dispatch, state, commit, rootState }) {
      try {
        let { data: issues } = await dispatch(
          `issues/${UPDATE}`,
          {
            id: "me",
            data: {
              issues: state.myIssues.map(({ id, issue_id, position }) => ({
                id,
                issue_id,
                position,
              })),
              project_id: rootState.currentProject.id,
            },
          },
          { root: true }
        );

        commit("setMyIssues", issues);
      } catch (error) {
        console.error(error);
        commit(
          "messages/showToast",
          { color: "red", message: i18n.t("errors." + error.message) },
          { root: true }
        );
      }
      await dispatch("api/refresh", "issues", { root: true });
    },
    async moveItemMyIssue(
      { state, commit, dispatch },
      { moved: { oldIndex, newIndex } }
    ) {
      commit("isUpdatingIssues", true);
      const issues = state.myIssues;
      const newIssues = array_move(issues, oldIndex, newIndex);
      issues.map((item, index) => {
        item.position = index + 1;
      });
      commit("setMyIssues", newIssues);
      await dispatch("updateMyIssues");
      commit("isUpdatingIssues", false);
    },
    async removeItemMyIssue({ state, commit, dispatch }, id) {
      commit("isUpdatingIssues", true);
      const issues = state.myIssues;
      const index = issues.findIndex((i) => i.id === id);
      issues.splice(index, 1);
      commit("setMyIssues", issues);
      await dispatch("updateMyIssues");
      commit("isUpdatingIssues", false);
    },
    async addItemMyIssue({ state, commit, dispatch }, issues) {
      commit("isUpdatingIssues", true);
      const myIssues = state.myIssues;
      try {
        if (!Array.isArray(issues) || issues.length === 0) {
          throw Error("Do not add empty array");
        }
        issues = issues
          .map((item) =>
            myIssues.findIndex((i) => i.issue_id === item.id) > -1
              ? false
              : item
          )
          .filter((i) => i);
        if (issues.length === 0) {
          throw Error("You add inner issues");
        }
        if (myIssues.length >= state.maxMyIssues) {
          throw Error("Not empty position");
        }
        if (myIssues.length + issues.length > state.maxMyIssues) {
          throw Error("Do not add more issues");
        }
        let lastIndex = 0;
        const newMyIssues = [
          ...myIssues.map((item, index) => {
            item.position = lastIndex = index + 1;
            return item;
          }),
          ...issues
            .map((item, index) => {
              if (myIssues.findIndex((i) => i.issue_id === item.id) !== -1) {
                return null;
              }
              return {
                id: null,
                issue_id: item.id,
                position: lastIndex + index + 1,
              };
            })
            .filter((i) => i),
        ];

        commit("setMyIssues", newMyIssues);
        await dispatch("updateMyIssues");
      } catch (error) {
        commit(
          "messages/showToast",
          { color: "red", message: i18n.t("errors." + error.message) },
          { root: true }
        );
      }
      commit("isUpdatingIssues", false);
    },
    async getFields({ commit, dispatch }) {
      const {
        data: { priorities, trackers, statuses },
      } = await dispatch("fields/getList", null, { root: true });
      commit("priorities", priorities);
      commit("trackers", trackers);
      commit("statuses", statuses);
    },
  },
};
