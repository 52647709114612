<template>
  <router-view />
</template>

<script>
export default {
  name: "WeightTickets",
  created() {
    this.$store.dispatch("getProjects");
  },
};
</script>
