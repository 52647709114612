var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.showAllIssues)?_c('div',[(_vm.myIssues.length)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.myIssues,"headers":_vm.fieldsTemp,"loading":_vm.myIssuesLoading,"item-key":"id","show-select":false,"disable-pagination":true,"hide-default-footer":true,"multi-sort":false},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"body",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [_c('draggable',{attrs:{"list":items,"tag":"tbody"},on:{"change":_vm.moveItemMyIssue}},_vm._l((items),function(item,index){return _c('tr',{key:item.id},_vm._l((headers),function(header,headerIndex){return _c('td',{key:item.id + header.value},[(headerIndex === 0)?_c('v-icon',{staticClass:"pr-2",staticStyle:{"cursor":"move"},attrs:{"medium":""}},[_vm._v(" mdi-dots-vertical ")]):_vm._e(),(header.value === 'index')?_c('span',[_vm._v(" "+_vm._s(index + 1)+" ")]):(header.value === 'issue.id')?_c('span',[_c('a',{attrs:{"href":item.issue.url,"target":"_blank"}},[_vm._v(_vm._s(item.issue.id))])]):(header.value !== 'actions')?_c('span',[_vm._v(" "+_vm._s(_vm.getValue(item, header))+" ")]):_vm._e(),(header.value === 'actions')?_c('span',[_c('v-btn',{attrs:{"icon":"","text":"","loading":_vm.isUpdatingIssues},on:{"click":function($event){return _vm.removeItemMyIssue(item.id)}}},[_c('v-icon',{staticClass:"pa-2",attrs:{"medium":""}},[_vm._v("mdi-trash-can-outline")])],1)],1):_vm._e()],1)}),0)}),0),_c('tfoot',[_c('tr',[(_vm.myIssues.length > 1)?_c('th',{staticClass:"text-right text-body-1",class:{
              'success--text': _vm.myIssues.length < _vm.maxMyIssues,
              'warning--text': _vm.myIssues.length === _vm.maxMyIssues,
            },attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(_vm.$t("resources.issues.messages.selected issues", { max: _vm.maxMyIssues, cur: _vm.myIssues.length, }))+" ")]):_vm._e()])])]}}],null,false,3987189051)}):_vm._e(),(!_vm.myIssuesLoading && _vm.myIssues.length === 0)?_c('v-card',{staticClass:"text-center"},[_c('v-card-text',{staticClass:"text-h3 red--text"},[_vm._v(" "+_vm._s(_vm.$t("resources.issues.messages.title"))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("resources.issues.messages.list empty"))+" ")])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }