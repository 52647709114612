<template>
  <va-show :item="item">
    <v-card class="mx-auto" max-width="500">
      <v-list>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("resources.results.fields.sum") }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title
                v-text="$t('resources.users.fields.name')"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="val in item.rates || []" :key="val.id">
            <v-list-item-icon>
              {{ val.sum }}
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="`${val.user.name} (${val.user.email})`"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
