import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ru from "vuetify/es5/locale/ru";
import en from "vuetify/es5/locale/en";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify, {
  iconfont: "mdi",
});

export default new Vuetify({
  lang: {
    current: process.env.VUE_APP_I18N_LOCALE || "ru",
    locales: { ru, en },
  },
});
