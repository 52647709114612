/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  admin.can(["dealer", "admin"]) && {
    icon: "mdi-view-dashboard",
    text: i18n.t("menu.dashboard"),
    link: "/tasks",
    name: "dashboard",
  },
  admin.getResourceLink("results"),
  { divider: true },
  admin.getResourceLink("projects"),
  admin.getResourceLink("settings"),
  { divider: true },
  admin.getResourceLink("users"),
];
