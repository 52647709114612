<template>
  <div v-if="isInit">
    <va-aside-layout :title="asideTitle">
      <results-show v-if="show" :item="item"></results-show>
    </va-aside-layout>
    <base-material-card class="px-5 py-3">
      <template v-slot:heading>
        <tab-project hideSwitch icon="mdi-all-inclusive" />
      </template>

      <list
        ref="list"
        :filter="{ project_id: this.currentProject.id }"
        :filters="filters"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        disable-create-redirect
        disable-create
        disable-query-string
      >
        <list-view
          :isShowActionButton="isShowActionButton"
          :isUpdatingIssues="isUpdatingIssues"
          :addItemMyIssue="addItemMyIssue"
          :fields="fields"
          :sortBy.sync="sortBy"
          :sortDesc.sync="sortDesc"
          def-sort="sum"
          :showRate="isAdmin"
          @onRowClick="onRowClick"
        />
      </list>
    </base-material-card>
  </div>
</template>

<script>
import List from "@/components/List";
import ListView from "@/components/ListView";
import TabProject from "@/views/dashboard/TabProject";
import {
  createNamespacedHelpers,
  mapState as rootMapState,
  mapGetters as mapRootGetters,
} from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("dashboard");

export default {
  components: { List, ListView, TabProject },
  props: ["resource", "title"],
  created() {
    this.$store.dispatch("getProjects");
    this.getFields();
  },
  data() {
    const fields = [
      {
        source: "sum",
        sortable: true,
        width: 180,
      },
      {
        source: "quantity",
        sortable: true,
        width: 150,
      },
      {
        source: "tracker.name",
        sortable: true,
        width: 220,
      },
      {
        source: "status.name",
        sortable: true,
        width: 120,
      },
      {
        source: "priority.name",
        sortable: true,
        width: 120,
      },
      {
        source: "id",
        sortable: true,
        width: 100,
      },
      {
        source: "subject",
        sortable: true,
      },
      {
        source: "created_on",
        type: "date",
        attributes: {
          format: "dateShort",
        },
        sortable: true,
        width: 140,
      },
      {
        source: "author",
        sortable: true,
        width: 150,
      },
    ];
    return {
      sortBy: ["sum"],
      sortDesc: [true],
      fields,
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  computed: {
    ...mapRootGetters({
      permissions: "auth/getPermissions",
    }),
    ...rootMapState(["isInit", "currentProject"]),
    ...mapState([
      "priorities",
      "trackers",
      "statuses",
      "isUpdatingIssues",
      "showAllIssues",
      "myIssues",
      "maxMyIssues",
      "priorities",
      "trackers",
      "statuses",
    ]),
    filters() {
      return [
        {
          source: "status_id",
          type: "autocomplete",
          attributes: {
            text: "name",
            chip: true,
            color: "orange",
            itemText: (i) => `${i.name} ${i.is_closed ? " (x)" : ""}`,
            multiple: true,
            itemValue: "id",
            choices: this.statuses,
          },
        },
        {
          source: "tracker_id",
          type: "autocomplete",
          attributes: {
            text: "name",
            chip: true,
            color: "orange",
            itemText: "name",
            multiple: true,
            itemValue: "id",
            choices: this.trackers,
          },
        },
        {
          source: "priority_id",
          type: "autocomplete",
          attributes: {
            text: "name",
            chip: true,
            color: "orange",
            itemText: "name",
            multiple: true,
            itemValue: "id",
            choices: this.priorities,
          },
        },
        "author",
        {
          source: "created_start",
          type: "date",
          attributes: {
            format: "dateShort",
          },
        },
        {
          source: "created_end",
          type: "date",
          attributes: {
            format: "dateShort",
          },
        },
      ];
    },
    isDealer() {
      return this.permissions.includes("dealer");
    },
    isAdmin() {
      return this.permissions.includes("admin");
    },
    isShowActionButton() {
      return this.isDealer && this.myIssues.length < this.maxMyIssues;
    },
  },
  methods: {
    ...mapActions(["getFields", "addItemMyIssue"]),
    showSortBy(values) {
      if (values.length === 0) {
        this.sortBy = ["sum"];
      }
    },
    onRowClick(value) {
      if (!this.isAdmin) {
        return;
      }
      this.show = true;
      this.item = value;
      this.asideTitle = this.$t("resources.results.showTitle", {
        id: this.item.id,
      });
    },
  },
};
</script>
