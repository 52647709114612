<template>
  <va-layout>
    <va-app-bar
      slot="app-bar"
      :header-menu="headerMenu"
      :profile-menu="profileMenu"
      color="yellow"
      disableReload
      dense
      dark
      @toggle="isToggle()"
    ></va-app-bar>
    <va-sidebar
      slot="sidebar"
      :menu="sidebarMenu"
      v-model="drawer"
      :mini-variant="mini"
    ></va-sidebar>
    <template slot="header">
      <va-breadcrumbs></va-breadcrumbs>
    </template>
    <va-aside slot="aside"></va-aside>
  </va-layout>
</template>

<script>
import nav from "../_nav";

export default {
  name: "App",
  data() {
    return {
      drawer: null,
      mini: false,
      headerMenu: [
        {
          link: "/",
          text: this.$t("menu.dashboard"),
        },
      ],
      profileMenu: [
        {
          icon: "mdi-account",
          text: this.$t("menu.profile"),
          link: "/profile",
        },
      ],
      sidebarMenu: nav(this.$i18n, this.$admin),
    };
  },
  methods: {
    isToggle() {
      this.$vuetify.breakpoint.lgAndUp
        ? (this.mini = !this.mini)
        : (this.drawer = !this.drawer);
    },
  },
};
</script>
<style>
/*.yellow-new {*/
/*  background-color: #ffcb05 !important;*/
/*  border-color: #ffcb05 !important;*/
/*  color: black !important;*/
/*}*/
</style>
