<template>
  <div v-if="!showAllIssues">
    <v-data-table
      v-if="myIssues.length"
      :items="myIssues"
      :headers="fieldsTemp"
      class="elevation-1"
      :loading="myIssuesLoading"
      item-key="id"
      :show-select="false"
      :disable-pagination="true"
      :hide-default-footer="true"
      :multi-sort="false"
    >
      <template v-slot:expanded-item="{ item }">
        {{ item.description }}
      </template>

      <template v-slot:body="{ items, headers }">
        <draggable :list="items" tag="tbody" @change="moveItemMyIssue">
          <tr v-for="(item, index) in items" :key="item.id">
            <td
              v-for="(header, headerIndex) in headers"
              :key="item.id + header.value"
            >
              <v-icon
                v-if="headerIndex === 0"
                medium
                class="pr-2"
                style="cursor: move"
              >
                mdi-dots-vertical
              </v-icon>
              <span v-if="header.value === 'index'">
                {{ index + 1 }}
              </span>
              <span v-else-if="header.value === 'issue.id'">
                <a :href="item.issue.url" target="_blank">{{
                  item.issue.id
                }}</a>
              </span>
              <span v-else-if="header.value !== 'actions'">
                {{ getValue(item, header) }}
              </span>
              <span v-if="header.value === 'actions'">
                <v-btn
                  icon
                  text
                  :loading="isUpdatingIssues"
                  @click="removeItemMyIssue(item.id)"
                >
                  <v-icon medium class="pa-2">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </draggable>
        <tfoot>
          <tr>
            <th
              :colspan="headers.length"
              class="text-right text-body-1"
              :class="{
                'success--text': myIssues.length < maxMyIssues,
                'warning--text': myIssues.length === maxMyIssues,
              }"
              v-if="myIssues.length > 1"
            >
              {{
                $t("resources.issues.messages.selected issues", {
                  max: maxMyIssues,
                  cur: myIssues.length,
                })
              }}
            </th>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
    <v-card
      v-if="!myIssuesLoading && myIssues.length === 0"
      class="text-center"
    >
      <v-card-text class="text-h3 red--text">
        {{ $t("resources.issues.messages.title") }}
      </v-card-text>
      <v-card-text>
        {{ $t("resources.issues.messages.list empty") }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import get from "lodash/get";
import draggable from "vuedraggable";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("dashboard");

export default {
  name: "ListMy",
  props: [],
  components: {
    draggable,
  },
  data() {
    return {
      fieldsTemp: [
        {
          text: this.$t("resources.issues.fields.position"),
          value: "position",
        },
        {
          text: this.$t("resources.issues.fields.tracker.name"),
          value: "issue.tracker.name",
          sortable: true,
        },
        {
          text: this.$t("resources.issues.fields.status.name"),
          value: "issue.status.name",
          sortable: true,
        },
        {
          text: this.$t("resources.issues.fields.priority.name"),
          value: "issue.priority.name",
          sortable: true,
        },
        {
          text: this.$t("resources.issues.fields.id"),
          value: "issue.id",
          sortable: true,
        },
        {
          text: this.$t("resources.issues.fields.subject"),
          value: "issue.subject",
          sortable: true,
        },
        {
          text: this.$t("resources.issues.fields.done_ratio"),
          value: "issue.done_ratio",
          type: "number",
          sortable: true,
        },
        {
          text: this.$t("resources.issues.fields.created_on"),
          value: "issue.created_on",
          type: "date",
          attributes: { format: "dateShort" },
          sortable: true,
        },
        {
          text: this.$t("resources.issues.fields.author"),
          value: "issue.author",
          sortable: true,
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState([
      "myIssuesLoading",
      "myIssues",
      "maxMyIssues",
      "isUpdatingIssues",
      "showAllIssues",
    ]),
  },
  methods: {
    ...mapActions(["moveItemMyIssue", "removeItemMyIssue"]),
    getValue(item, header) {
      let value = get(item, header.value);
      if (header.type === "date") {
        value = this.$d(new Date(value), "dateShort");
      }

      return value;
    },
  },
};
</script>
